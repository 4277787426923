@import 'styles/breakpoints';
@import 'styles/animations';

.root {
    display: flex;
    width: 100%;
    flex-direction: column;
    color: var(--colors-blue);
    text-align: center;
}

.eyebrow {
    margin: var(--block-padding-end) 0 1.2rem;
    text-transform: uppercase;
}

.copy {
    max-width: var(--copy-max-width, 29rem);
    margin: 0 auto;
}

.cardsGrid {
    display: grid;
    overflow: hidden;
    width: 100%;
    max-width: var(--block-max-width);
    padding: var(--cards-grid-padding, var(--block-padding-end) 2.4rem);
    margin: 0 auto;
    grid-auto-columns: 1fr;
    grid-gap: 2.4rem;
    grid-template-areas: var(--gridColumnAreas, 'a');
    list-style: none;
}

.listItem {
    box-sizing: border-box;
}

@include medium {
    .root {
        --gridColumnAreas: 'a a';
        --copy-max-width: 45rem;
    }
}

@include large {
    .root {
        --gridColumnAreas: 'a a a';
        --copy-max-width: 88rem;
    }
}

@include xxLarge {
    .root {
        --cards-grid-padding: var(--block-padding-end) 0;
    }
}
