@import 'styles/breakpoints';
@import 'styles/fonts';

.root {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    padding: var(--block-padding-end) 0;

    &.flushTop {
        padding-top: 0;
    }

    &.flushBottom {
        padding-bottom: 0;
    }
}

.imageBoxOuter {
    position: relative;
    overflow: hidden;
    width: 100%;
    min-width: 5rem;
    height: var(--aspect-height, 100vh);
    min-height: var(--aspect-min-height, 70rem);
}

.imageBoxInner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
}

.contentBox {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: var(--content-box-padding, 0 2.4rem);

    .light & {
        color: var(--colors-cream);
    }

    .dark & {
        color: var(--colors-blue);
    }
}

.contentBoxInner {
    display: flex;
    width: 100%;
    max-width: var(--content-box-max-width, 80%);
    height: 100%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.eyebrow {
    margin-bottom: var(--eyebrow-margin-bottom, 2.4rem);
    font-family: $GTWalsheimCond;
    font-size: 1.6rem;
    font-weight: bold;
    letter-spacing: 0.2rem;
    line-height: 120%;
    text-transform: uppercase;
}

.headlineImageBoxOuter {
    position: relative;
    overflow: hidden;
    width: 100%;
    min-width: 5rem;
    max-width: 66rem;
    padding-bottom: var(--headline-image-box-padding-bottom, 76%);
}

.headlineImageBoxInner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.headlineImage {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: left center;
}

.copy {
    margin-top: var(--copy-margin-top, 2.4rem);
    margin-bottom: 0;

    a {
        text-decoration: underline;
    }

    button {
        display: inline;
        overflow: visible;
        padding: 0;
        border: 0;
        margin: 0;
        appearance: none;
        background: transparent;
        color: inherit;
        font: inherit;
        -webkit-font-smoothing: inherit;
        -moz-osx-font-smoothing: inherit;
        line-height: normal;
        outline: none;
        text-align: left;
        text-decoration: underline;
    }
}

.cta {
    margin-top: var(--cta-margin-top, 2.4rem);
}

@include medium {
    .root {
        --content-box-padding: 0 var(--block-padding-side);
        --headline-image-box-padding-bottom: 31.8%;
        --aspect-height: calc(100vh - 6rem);
        --aspect-min-height: 60rem;
    }
}

@include large {
    .root {
        --headline-image-box-padding-bottom: 31.8%;
        --content-box-max-width: 66rem;
        --eyebrow-margin-bottom: 3.6rem;
        --copy-margin-top: 3.6rem;
        --cta-margin-top: 3.6rem;
        --aspect-height: calc(100vh - 8rem);
    }

    .eyebrow {
        font-size: 2rem;
    }
}

@include xxLarge {
    .root {
        --aspect-min-height: 72rem;
    }
}
