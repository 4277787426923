@use 'sass:math';
@import 'styles/animations';
@import 'styles/breakpoints';
@import 'styles/fonts';

@keyframes animate-in-nav-item {
    0% {
        opacity: 0;
        transform: scale(0.6) translateY(-8px);
    }

    100% {
        opacity: 1;
        transform: scale(1) translateY(0);
    }
}

@mixin hover-effect {
    .aspectBox {
        transform: translateY(-0.4rem);
    }
}

.menu {
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    color: var(--colors-blue);
}

.isEntered {
    .root {
        transform: translate3d(0, 0%, 0);
        transition: transform $default $easeInCubic;
    }
}

.isExiting {
    .root {
        transform: translate3d(0, -100%, 0);
        transition: transform 0.3s $easeInCubic;
    }
}

.isExited {
    .root {
        pointer-events: none;
        transform: translate3d(0, -100%, 0);
    }
}

.root {
    min-height: 6rem;
    box-shadow: 0 0.5rem 2rem rgba(188, 185, 151, 20%);

    .menu & {
        background-color: var(--colors-white);
    }
}

.rootInner {
    display: flex;
    width: 100%;
    max-width: 144rem;
    flex-wrap: wrap;
    justify-content: center;
    padding: 1rem 4vw 4rem;
    margin: 0 auto;

    .menu & {
        padding-bottom: 2rem;
    }
}

.item {
    display: flex;
    flex-basis: 34%;
    flex-direction: column;
    align-items: center;
    margin: 0.7rem 2rem;
    animation-delay: calc(var(--animation-order) * #{math.div($fast, 3)});
    animation-duration: $slow;
    animation-fill-mode: both;
    animation-name: animate-in-nav-item;
    animation-timing-function: $easeOutCubic;
    text-align: center;

    @media (hover: hover) {
        &:hover:not(.itemActive) {
            @include hover-effect;
        }
    }

    &:active:not(.itemActive) {
        @include hover-effect;
    }

    .menu & {
        padding-bottom: 0;
    }
}

.itemActive {
    cursor: default;

    .label {
        padding-bottom: 0.4rem;
        border-bottom: 0.2rem solid var(--colors-blue);
    }
}

.aspectBox {
    position: relative;
    overflow: hidden;
    margin-bottom: 0.8rem;
    transform: translateY(0);
    transition: transform $default $easeInOutCubic;
}

.aspectBox,
.aspectBox > span {
    width: 6rem;
    height: 6rem;
}

.icon {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 7rem;
    height: 7rem;
    object-fit: scale-down;
    object-position: center bottom;
}

.label {
    color: var(--colors-blue);
    text-transform: uppercase;
}

.menuCurrentTitle {
    display: none;
    font-family: $GTWalsheimCond;
    font-size: 1.6rem;
    letter-spacing: 0.2rem;
    line-height: 120%;
    text-transform: uppercase;
}

.menuCurrentTitleSeparator {
    margin: 0 0.6rem;
}

.menuTitle {
    text-transform: uppercase;
}

.menuTrigger {
    position: relative;
    z-index: 1;
    display: flex;
    height: 6rem;
    align-items: center;
    justify-content: center;
    background-color: var(--colors-white);
    color: var(--colors-blue);
    cursor: pointer;
}

.buttonCircle {
    margin-left: 2rem;
    transform: rotate(0deg);
    transition: transform $default $ease;

    &.isEntered {
        transform: rotate(-180deg);
    }
}

@include medium {
    .root {
        flex-wrap: nowrap;
        margin-top: 0;
        margin-right: auto;
        margin-left: auto;
    }

    .item {
        margin: 1rem 0 0;
    }

    .menuCurrentTitle {
        display: block;
    }

    .rootInner {
        flex-wrap: nowrap;
    }

    .aspectBox {
        display: flex;
        align-items: center;
        margin-bottom: 1.2rem;
    }

    .aspectBox,
    .aspectBox > span {
        width: 7rem;
        height: 7rem;
    }
}
