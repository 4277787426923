@import 'styles/breakpoints';

.card {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    padding-top: 125%;
    background-color: var(--card-background-color);
}

.cardLink {
    width: 100%;
    height: 100%;
}

.cardInner {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    overflow: hidden;
    width: 100%;
    height: 100%;
    flex-direction: column;
}

.icon {
    position: relative;
    width: 2.8rem;
    height: 2.8rem;
    margin-left: 1.5rem;
    color: var(--colors-cream);
}

.image {
    display: flex;
    width: 100%;
    height: 100%;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    padding: 3.6rem 3.6rem 25%;

    .card--card & {
        margin-bottom: 13%;
    }

    picture,
    img {
        width: 100%;
        height: auto;
    }
}

.cta {
    position: absolute;
    bottom: 0;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 0 3.6rem 3.6rem;
    color: var(--colors-cream);
    letter-spacing: 1.5px;
    line-height: 1.5;
    text-align: center;
    text-transform: uppercase;

    .card--card & {
        position: absolute;
        bottom: 0;
        width: 100%;
        padding: 0 3.6rem 10%;
    }

    .card--dark-text & {
        color: var(--colors-blue);
    }
}

.card--detail {
    padding-top: 132%;

    .cta {
        letter-spacing: inherit;
        text-transform: inherit;
    }

    &.fullWidth {
        .image {
            padding: 3.6rem 0;
        }
    }
}

@include medium {
    .cta {
        padding: 0 3.6rem 5.5rem;
    }

    .image {
        .card--card & {
            padding: 3.6rem 5.6rem;
        }
    }
}

@include large {
    .cta {
        padding: 0 3.6rem 3.6rem;
    }

    .image {
        padding: 3.6rem 5.6rem 25%;
    }

    .card--detail {
        padding-top: 100%;

        &.fullWidth {
            padding-top: 57%;

            .image {
                height: 75%;
                padding: 1rem 5rem;
            }
        }
    }

    .card--square,
    .card--trio {
        padding-top: 100%;
    }
}

@include xxLarge {
    .card--detail {
        padding-top: 115%;
    }

    .image {
        padding: 3.6rem 3.6rem 25%;
    }

    .cta {
        padding: 0 3.6rem 5.5rem;

        .card--card & {
            padding: 0 3.6rem 10%;
        }
    }
}
