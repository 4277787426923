@import 'styles/breakpoints';
@import 'styles/animations';
@import 'styles/fonts';

@keyframes up {
    0% {
        transform: translateY(calc(var(--content-area) * 0.1));
    }

    100% {
        transform: translateY(0);
    }
}

@keyframes fade-up {
    0% {
        opacity: 0;
        transform: translateY(calc(var(--content-area) * 0.05));
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.root {
    min-height: 100%;
    margin-top: calc(var(--header-height) * -1);

    --content-area: 100vw;
    --split-color-top-ratio: 1.13;
}

.hero {
    position: relative;
    display: flex;
    overflow: hidden;
    flex-direction: column;
    align-items: center;

    // used to set scroll parallax top of viewport
    padding-top: var(--header-height);
    animation: fade-up 500ms $easeOutQuad 0s both;

    .showIntro & {
        animation: up 800ms $easeOutQuad 0.9s both;
    }

    background-color: #ffe4af;

    &::before {
        position: absolute;
        bottom: 0;
        display: block;
        width: 100%;
        height: 20vw;
        background: var(--colors-cream);
        content: '';
    }
}

.heroImage {
    position: relative;
    left: -4%;
    width: 110%;
    margin-top: 20%;
}

.inner {
    position: relative;
    display: flex;
    width: 100%;
    max-width: var(--block-max-width-plus-padding);
    flex-direction: column;
    align-items: center;
    padding: 0;
    margin: var(--block-padding-end) 0 0;
}

.title {
    width: 85vw;
    color: var(--colors-blue);
    font-family: $GTWalsheimUltraBold;
    font-size: 9.35vw;
    font-style: normal;
    font-weight: 800;
    line-height: 85%; /* 4.08rem */
    text-align: center;
    text-transform: uppercase;
    text-wrap: initial;
    will-change: transform;

    span {
        position: relative;
    }

    sup {
        position: absolute;
        top: 11%;
        bottom: 100%;
        margin-left: 0.3em;
        font-family: $GTWalsheim;
        font-size: max(1.6rem, 0.18em);
        line-height: 100%;
        vertical-align: middle;
    }
}

.cta {
    position: relative;
    z-index: 10;
    margin-top: 2rem;
}

.headingGroup {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.image {
    position: relative;
    z-index: 1;
    width: 100%;
    margin-top: -6.7vw;
    will-change: transform;
}

.heroImageTop {
    display: none;
}

.nav {
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: 0 2.4rem;

    img {
        display: block;
        width: 100%;
    }
}

.link {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 1.2rem 0;
    border-bottom: 1px solid rgba(0, 0, 0, 10%);
    gap: 1.4rem;
    text-decoration: none;

    &:last-child {
        border-bottom: none;
    }
}

.linkText {
    flex: 1;
    color: var(--colors-blue);
}

.aspectBox {
    position: relative;
    display: flex;
    overflow: hidden;
    width: 7.2rem;
    height: 7.2rem;
    align-items: center;
}

.category {
    margin-bottom: 10px;
}

.productGrid {
    padding: 0;
    margin: 0;
    list-style: none;
}

@include medium {
    .root {
        --content-area: min(
            calc(100vw - var(--block-padding-side) - var(--block-padding-side)),
            1420px
        );
        --product-scale: 0.93;
        --split-color-top-ratio: 0.475;
    }

    .hero {
        &::before {
            position: absolute;
            bottom: 0;
            display: block;
            width: 100%;
            height: 16.5vw;
            content: '';
        }
    }

    .inner {
        height: calc(var(--content-area) * 0.657);
        margin: 0;
    }

    .headingGroup {
        align-items: flex-start;
        align-self: flex-start;
        margin-top: calc(var(--content-area) * 0.08);
        margin-left: var(--block-padding-side);
    }

    .heroImage {
        --image-width: calc(var(--content-area) * var(--product-scale));

        position: absolute;
        top: calc(var(--content-area) * 0.19);
        right: calc(
            var(--block-padding-side) - calc(var(--content-area) * 0.07)
        );
        left: auto;
        width: var(--image-width);
        margin-top: auto;
        pointer-events: none;
        transform: translateY(-12%);
    }

    .heroImageTop {
        z-index: 111;
        display: block;
        pointer-events: none;
    }

    .image {
        margin-top: 0;
    }

    .title {
        max-width: calc(var(--content-area) * 0.4);
        font-size: calc(var(--content-area) * 0.1082);
        text-align: left;
        transition: transform 0.1s ease-out;
    }

    .hidden {
        visibility: hidden;
    }

    .cta {
        margin-top: 3.6rem;
    }

    .nav {
        width: 100%;
        max-width: 81rem;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        padding: 2.4rem var(--block-padding-side);
        margin-top: calc(var(--content-area) * 0.2);
    }

    .link {
        display: flex;
        width: auto;
        min-width: min-content;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        padding: 2.4rem;
        border-bottom: none;
        gap: 1.4rem;
        text-decoration: none;

        &:last-child {
            border-bottom: none;
        }
    }

    .aspectBox {
        width: 8rem;
        height: 8rem;
        align-items: flex-end;
    }

    .linkText {
        text-align: center;
        text-transform: uppercase;
    }

    .linkIcon {
        display: none;
    }
}

@include large {
    .nav {
        width: 100%;
        max-width: var(--block-max-width-plus-padding);
        flex-wrap: nowrap;
    }

    .aspectBox {
        width: 9rem;
        height: 9rem;
    }

    .link {
        width: 100%;
    }
}

@include xLarge {
    .nav {
        flex-wrap: nowrap;
    }

    .aspectBox {
        width: 12rem;
        height: 12rem;
    }
}
