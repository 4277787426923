@import 'styles/animations';
@import 'styles/breakpoints';
@import 'styles/colors';
@import 'styles/mixins';
@import 'styles/fonts';

@keyframes mask-off {
    0% {
        clip-path: xywh(0 -0% 100% 100%);
        visibility: visible;
    }

    100% {
        clip-path: xywh(0 calc(-100% + var(--header-height)) 100% 100%);
        visibility: hidden;
    }
}

.hero {
    min-height: calc(100vh - var(--header-height));
    background: #ccc;
}

.splash {
    position: fixed;
    z-index: 11;
    top: 0;
    display: none;
    width: 100%;
    min-height: 100vh;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: var(--colors-blue);
    clip-path: xywh(0 -0% 100% 100%);
    color: var(--colors-cream);
    font-size: 71.5vw;
    pointer-events: none;

    &.showIntro {
        display: flex;
        animation: mask-off 0.8s $easeInOutQuart 0.7s both;
    }
}

.logo {
    width: 1em;
    margin-left: -0.5vw;
    color: var(--colors-cream);
}

.tagline {
    margin-top: 1em;
    margin-bottom: 0;
    margin-left: -0.2em;
    color: var(--colors-cream);
    font-family: $GTWalsheimUltraBold;
    font-size: 0.069em;
    font-weight: bold;
    line-height: 100%;
    text-align: center;
    text-transform: uppercase;
}

@include medium {
    .splash {
        font-size: 31.5vw;
    }
}
