@import 'styles/breakpoints';
@import 'styles/colors';
@import 'styles/mixins';
@import 'styles/animations';

.root {
    background: radial-gradient(
            68.26% 53.66% at 50% 49.9%,
            #71c5ea 0%,
            #2495d9 100%
        ),
        #2495d9;
}

.content {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.eyebrow {
    padding-bottom: 0.8rem;
    font-size: 1.2rem;
    text-transform: uppercase;
}

.headline {
    position: relative;
    font-size: clamp(2rem, -0.0038rem + 5.3435vw, 4.1rem);
    text-align: center;
    text-transform: uppercase;

    b {
        @include block-text(var(--colors-cream));

        font-size: clamp(3rem, 0.042rem + 7.888vw, 6.1rem);
    }

    &:last-of-type {
        padding-bottom: 2rem;
    }
}

.cta {
    padding: 1.15rem 1rem;

    span {
        font-size: 1.4rem;
    }
}

@include medium {
    .eyebrow {
        font-size: 2rem;
    }

    .headline {
        font-size: 4.8rem;

        b {
            font-size: 7.2rem;
        }

        &:last-of-type {
            padding-bottom: 4rem;
        }
    }

    .cta {
        padding: 1.3rem 2.4rem;

        span {
            font-size: 2rem;
        }
    }
}

@include large {
    .eyebrow {
        padding-bottom: 1.2rem;
    }

    .headline {
        b {
            font-size: 6.4rem;
        }
    }
}

@include xLarge {
    .headline {
        b {
            font-size: 7.2rem;
        }
    }
}
