@import 'styles/breakpoints';
@import 'styles/fonts';
@import 'styles/colors';
@import 'styles/mixins';

.root {
    padding: var(--block-padding-end) 2.4rem;
    background-color: var(--colors-ccBlueMedium);
    color: var(--colors-blue);
}

.container {
    position: relative;
    width: 100%;
    height: 100%;
}

.header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 3rem;
    margin: 0 auto var(--block-padding-end);
    text-align: center;
}

.headline {
    @include block-text(var(--colors-cream));

    max-width: 46rem;
    margin-bottom: 0.8rem;
    font-size: 6.8rem;
    text-align: center;
}

.subHeadline {
    font-size: 4rem;
    text-transform: uppercase;
}

.body {
    max-width: 52.4rem;
    margin: 2.4rem 0;
}

.cards {
    --gridColumnAreas: 'a a';

    display: grid;
    max-width: 124rem;
    margin: 0 auto;
    grid-auto-columns: 1fr;
    grid-auto-rows: 1fr;
    grid-gap: 1.2rem;
    grid-template-areas: var(--gridColumnAreas);
}

.card {
    position: relative;
    overflow: hidden;
    width: 100%;
    max-width: 124.8rem;
    height: 100%;
    padding: 2rem 1.2rem;
    border-radius: 1rem;
    color: var(--colors-blue);
}

@include medium {
    .card {
        padding: 5.6rem 3.6rem;
        border-radius: 2rem;
    }

    .headline {
        max-width: 74rem;
        margin-bottom: 0.6rem;
        font-size: 11rem;
    }

    .subHeadline {
        font-size: 6rem;
    }
}

@include large {
    .card {
        padding: 3.8rem 3.2rem 4.1rem;
    }

    .cards {
        --gridColumnAreas: 'a a a';
    }
}
