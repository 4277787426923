@import 'styles/animations';
@import 'styles/breakpoints';
@import 'styles/colors';
@import 'styles/mixins';
@import 'styles/fonts';

.root {
    --spinner-color: var(--colors-blue);

    display: flex;
    overflow: hidden;
    width: 100%;
    justify-content: center;
    padding: var(--block-padding-end) 2.4rem var(--last-block-padding-bottom);
    background-color: var(--colors-ccBlueLight);
    color: var(--colors-blue);
}

.inner {
    display: flex;
    width: 100%;
    max-width: var(--block-max-width);
    flex-direction: column;
}

.contentWrapper {
    display: flex;
    max-width: 61.7rem;
    flex-direction: column;
}

.eyebrow {
    max-width: 33rem;
    margin-bottom: 0;
    font-family: $GTWalsheimUltraBold;
    font-size: 4rem;
    font-weight: bold;
    line-height: 85%;
    text-transform: uppercase;
}

.headline {
    @include block-text(var(--colors-cream));

    line-height: 1;
}

.subHeadline {
    margin-top: 2.4rem;
    margin-bottom: 2.4rem;
}

.asterisk {
    margin-bottom: 2.4rem;
}

.inputLockup {
    position: relative;
    width: 100%;
    max-width: 47rem;
    height: var(--input-height, 6.7rem);

    @include flex-center;
}

.inputWrap {
    width: 100%;
    height: 100%;
}

.input {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    padding: 1rem 3rem;
    border: none;
    border-radius: 29rem;
    background-color: var(--colors-white);
    color: var(--colors-blue);
    font-weight: 500;

    &::placeholder {
        color: var(--colors-blue);
        font-weight: 500;
        opacity: 0.4;
    }

    .inputSuccess & {
        color: var(--colors-cheeseMexican4Cheese);
        pointer-events: none;

        &::placeholder {
            color: var(--colors-blue);
        }
    }
}

.submitButtonWrap {
    position: absolute;
    right: 2rem;
    width: 4rem;
    height: 4rem;
}

.buttonSpinner {
    position: absolute;
    top: 50%;
    width: 2.8rem;
    height: 2.8rem;
    opacity: 0;
    pointer-events: none;
    transform: translateY(-50%);

    .loading & {
        opacity: 1;
    }
}

.submitArrow {
    width: 1.8rem;
    height: 1.8rem;
    color: var(--colors-white);
}

.submitButton {
    width: 100%;
    height: 100%;
    border: 2px solid var(--colors-blue);
    border-radius: 100%;
    background-color: var(--colors-blue);
    transition: transform 0.25s;
    will-change: transform;

    &:hover {
        transform: scale(1.1);

        &.disabled {
            transform: none;
        }
    }

    .loading & {
        opacity: 0;
    }

    .inputSuccess & {
        display: none;
    }
}

.reCaptchaLegal {
    margin: 1.4rem;
}

.joinError {
    color: var(--colors-cheeseSharpCheddar);
}

.deckWrapper {
    width: 100%;
    margin: 4rem 0;
}

.checkboxWrapper {
    margin: 2.4rem;

    &.hide {
        display: none;
    }
}

.checkbox {
    position: relative;
    display: block;
    width: 100%;
    padding-left: 2rem;
    cursor: pointer;
    text-align: left;
    user-select: none;

    a {
        text-decoration: underline;
    }

    input {
        position: absolute;
        width: 0;
        height: 0;
        cursor: pointer;
        opacity: 0;
    }

    input:checked ~ .checkmark {
        background-color: var(--colors-blue);

        &::after {
            display: block;
        }
    }
}

.checkboxError {
    color: var(--colors-cheeseSharpCheddar);
}

.checkmark {
    position: absolute;
    top: 50%;
    left: -2rem;
    width: 2.2rem;
    height: 2.2rem;
    border: none;
    border-radius: 5px;
    background-color: var(--colors-white);
    transform: translateY(-50%);

    &::after {
        position: absolute;
        display: none;
        content: '';
    }
}

.checkbox .checkmark::after {
    top: 2px;
    left: 6px;
    width: 7px;
    height: 12px;
    border: solid var(--colors-cream);
    border-width: 0 2px 2px 0;
    border-radius: 2px;
    transform: rotate(45deg);
}

@include medium {
    .root {
        padding: var(--block-padding-end) var(--block-padding-side)
            var(--last-block-padding-bottom);
    }
}

@include large {
    .inner {
        flex-direction: row;
        justify-content: space-between;
    }

    .eyebrow {
        max-width: 50rem;
        font-size: 6rem;
    }

    .subHeadline {
        margin-top: 3.6rem;
        margin-bottom: 3.6rem;
    }

    .asterisk {
        margin-bottom: 3.6rem;
    }

    .deckWrapper {
        width: 50%;
    }
}

@include xLarge {
    .root {
        max-height: 80rem;
    }
}
