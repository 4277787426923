@import 'styles/animations';
@import 'styles/breakpoints';
@import 'styles/fonts';

@mixin video-player-hover {
    .background {
        &::after {
            background-color: rgba(0, 0, 0, 40%);
        }
    }
}

.root {
    overflow: hidden;
    width: 100%;
}

.videoPlayerYT {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: var(--videoPlayerYT-padding-bottom, 158%);
    cursor: pointer;

    @media (hover: hover) {
        &:hover {
            @include video-player-hover;
        }
    }

    &:active {
        @include video-player-hover;
    }

    &.isActive {
        .foreground {
            opacity: 1;
        }
    }

    &.heroVideo {
        height: 100%;
        padding-bottom: 0;
    }
}

.image,
.background,
.foreground,
.videoOverlayBackdrop,
.videoOverlay,
.iFrame {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.image {
    object-fit: cover;
    object-position: center;
}

.background {
    &::after {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 20%);
        content: '';
        transition: background-color $easeOutQuint $slow;
    }
}

.foreground {
    left: 50%;
    max-width: 59.6rem;
    padding: var(--foreground-padding, var(--block-padding-end) 4rem);
    color: var(--colors-cream);
    opacity: 0;
    transform: translateX(-50%);
    transition: opacity $slow;
}

.foregroundContentWrapper {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: var(--foreground-align-items, center);
    justify-content: var(--foreground-justify-content, center);
    text-align: var(--foreground-text-align, center);
}

.iconWrapper {
    position: relative;
}

.icon {
    width: 8.8rem;
    height: 6.5rem;
}

.headlineOuterWrapper {
    width: 100%;
    max-width: 83.8rem;
    margin-top: 2.6rem;
}

.headlineWrapper {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 0;
    padding-bottom: var(--headline-wrapper-padding-bottom, 67.5%);
}

.headlineWrapperInner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    img {
        margin: 0 auto;
    }
}

.headline {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center center;
}

.descriptionWrapper {
    margin-top: 2.6rem;
}

.description {
    max-width: 20rem;
    margin: 0 auto;
    font-family: $GTWalsheim;
    font-size: 2.1rem;
    font-weight: 500;
    line-height: 110%;
}

.playVideo {
    margin-top: var(--playVideo-margin-top, 1.5rem);
}

.videoOverlay {
    display: flex;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    padding: var(--videoOverlay-padding, 0);
    opacity: 0;
    pointer-events: none;
    transition: opacity $easeOutQuint $slow,
        visibility $easeOutQuint $slow $slow;
    visibility: hidden;

    &.isOpen {
        opacity: 1;
        pointer-events: auto;
        transition: opacity $easeOutQuint $slow, visibility $easeOutQuint $slow;
        visibility: visible;
    }
}

.videoOverlayBackdrop {
    background-color: rgba(0, 0, 0, 80%);

    &.transparent {
        background-color: transparent;
    }
}

.videoOverlayClose {
    position: absolute;
    top: 0;
    right: 0;
    padding: var(--videoOverlayClose-padding, 2rem 2.5rem);
}

.videoOverlayCloseIcon {
    position: relative;
    width: 2.2rem;
    height: 2.2rem;
    color: var(--colors-cream);

    svg {
        width: 2.2rem;
        height: 2.2rem;
    }
}

.videoOverlayInnerWrap {
    position: relative;
    width: 100%;
    max-width: var(--block-max-width-plus-padding);
    padding: var(--block-padding);
}

.videoOverlayInner {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    background-color: var(--colors-cheeseBlackPepper);
    opacity: 0;
    transition: opacity $easeOutQuint $slow,
        visibility $easeOutQuint $slow $slow;
    visibility: hidden;

    .isOpen & {
        opacity: 1;
        transition: opacity $easeOutQuint $superSlow * 2 0.2s,
            visibility $easeOutQuint $superSlow 0.2s;
        visibility: visible;
    }
}

@include medium {
    .videoPlayerYT {
        --videoPlayerYT-padding-bottom: 56.25%;
        --videoOverlay-padding: var(--block-padding-end);
        --headline-wrapper-padding-bottom: 27.5%;

        &.heroVideo {
            .foreground {
                max-width: var(--block-max-width-plus-padding);
            }
        }
    }

    .videoOverlay {
        --videoOverlayClose-padding: 3.1rem 3rem;
    }

    .foreground {
        .embeddedEntry & {
            padding: 4.8rem;
        }
    }

    .foregroundContentWrapper {
        max-width: 88.3rem;
        margin: 0 auto;
    }
}

@include large {
    .videoPlayerYT {
        --playVideo-margin-top: 3rem;
    }

    .icon {
        width: 15rem;
        height: 11rem;
    }

    .description {
        max-width: none;
        font-size: 3.6rem;
    }

    .headlineOuterWrapper {
        margin-top: 4.6rem;
    }

    .descriptionWrapper {
        margin-top: 4.6rem;
    }
}
