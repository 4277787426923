.bold {
    font-weight: bold;
}

.italic {
    font-style: italic;
}

.underline {
    text-decoration: underline;
}

.paragraph {
    margin: 0 0 2rem;

    &:last-child {
        margin-bottom: 0;
    }
}

.blockquote {
    position: relative;
    display: block;
    max-width: var(--news-richText-max-width);
    margin: 11rem auto 4.8rem;
    text-align: center;

    span {
        position: absolute;
        top: -8rem;
        left: 50%;
        width: 9.2rem;
        height: 6.4rem;
        transform: translateX(-50%);

        path {
            fill: var(--colors-cream);
        }
    }

    p {
        position: relative;
        font-size: 2.4rem;
    }
}

.unorderedList {
    padding: 0 0 0 3.4rem;
    list-style: initial;
    text-align: left;

    li {
        padding-left: 0.6rem;

        &::marker {
            font-size: 1.3em;
        }
    }
}

.orderedList {
    font-weight: 500;
    text-align: left;
}

.listItem {
    margin: 0;

    &:last-of-type {
        margin: 0;
    }
}

.heading {
    padding: 1.5em 0;
    margin: 0 0 2rem;

    &:first-of-type {
        padding-top: 0;
    }
}
