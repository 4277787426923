@import 'styles/breakpoints';
@import 'styles/animations';

.child {
    will-change: opacity, transform;

    &.disabled {
        animation: none;
        opacity: 1;
    }

    &.fade-up {
        animation-duration: $default;
        animation-fill-mode: forwards;
        animation-timing-function: $easeOutQuad;
        opacity: 0;
        transform: translateY(2rem);

        .isInView & {
            animation-name: fade-up;
        }
    }
}

.arrowRight {
    position: relative;
    width: 6rem;
    height: 1.3rem;

    svg {
        width: 6rem;
        height: 1.3rem;
    }

    &.pop {
        animation-duration: 0.35s;
        animation-fill-mode: forwards;
        animation-timing-function: $easeOutQuad;
        transform: scale(0);

        .isInView & {
            animation-name: pop;
        }
    }

    &.small,
    &.smallMobile {
        width: 4.9rem;
        height: 1.1rem;

        svg {
            width: 4.9rem;
            height: 1.1rem;
        }
    }

    &.smallMobile {
        @include large {
            width: 6rem;
            height: 1.3rem;

            svg {
                width: 6rem;
                height: 1.3rem;
            }
        }
    }
}

@keyframes fade-up {
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes pop {
    0% {
        transform: scale(1);
    }

    40% {
        transform: scale(1.15);
    }

    55% {
        transform: scale(1.1);
    }

    75% {
        transform: scale(1.05);
    }

    100% {
        transform: scale(1);
    }
}
