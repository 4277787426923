@import 'styles/breakpoints';
@import 'styles/animations';
@import 'styles/fonts';

@mixin button-background($color1, $color2) {
    background-image: linear-gradient(
        0deg,
        $color1 0%,
        $color1 50%,
        $color2 50%,
        $color2 100%
    );
}

.root {
    display: flex;
    width: fit-content;
    height: 4rem;
    align-items: center;
    justify-content: center;
    border: 0;
    background-color: transparent;
    color: var(--colors-blue);
    font-family: $GTWalsheimUltraBold;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 800;
    gap: 0.6rem;
    line-height: 1;
    text-transform: uppercase;
    text-wrap: nowrap;

    &.rightAlignIcon {
        flex-direction: row-reverse;
    }
}

.unstyled {
    height: auto;

    &[href] {
        text-decoration: underline;
    }
}

.colors {
    &-filled-blue-ocean {
        --base-color: var(--colors-blue);
        --hover-color: var(--colors-blueOcean);
        --text-color: var(--colors-cream);
        --text-hover-color: var(--colors-cream);
    }

    &-filled-blue-cream {
        --base-color: var(--colors-blue);
        --hover-color: var(--colors-cream);
        --text-color: var(--colors-cream);
        --text-hover-color: var(--colors-blue);
    }

    &-filled-cream-ocean {
        --base-color: var(--colors-cream);
        --hover-color: var(--colors-blueOcean);
        --text-color: var(--colors-blue);
        --text-hover-color: var(--colors-blue);
    }

    &-filled-cream-white {
        --base-color: var(--colors-cream);
        --hover-color: var(--colors-white);
        --text-color: var(--colors-blue);
        --text-hover-color: var(--colors-blue);
    }

    &-makersFilled-cream-black {
        --base-color: var(--colors-cream);
        --hover-color: var(--colors-makersReserveOffBlack);
        --text-color: var(--colors-makersReserveOffBlack);
        --text-hover-color: var(--colors-cream);
    }

    &-makersFilled-gold-cream {
        --base-color: var(--colors-makersReserveGold);
        --hover-color: var(--colors-cream);
        --text-color: var(--colors-cream);
        --text-hover-color: var(--colors-makersReserveGold);
    }

    &-outlined-blue-blue {
        --base-color: transparent;
        --hover-color: var(--colors-blue);
        --text-color: var(--colors-blue);
        --text-hover-color: var(--colors-cream);
    }

    &-outlined-cream-cream {
        --base-color: transparent;
        --hover-color: var(--colors-cream);
        --text-color: var(--colors-cream);
        --text-hover-color: var(--colors-blue);
    }

    &-makersOutlined-cream-cream {
        --base-color: transparent;
        --hover-color: var(--colors-cream);
        --text-color: var(--colors-cream);
        --text-hover-color: var(--colors-makersReserveGold);
    }

    &-makersOutlined-gold-gold {
        --base-color: transparent;
        --hover-color: var(--colors-makersReserveGold);
        --text-color: var(--colors-makersReserveGold);
        --text-hover-color: var(--colors-cream);
    }

    &-inline-blue-ocean {
        --text-color: var(--colors-blue);
        --text-hover-color: var(--colors-blueOcean);
    }

    &-inline-blue-cream {
        --text-color: var(--colors-blue);
        --text-hover-color: var(--colors-cream);
    }

    &-inline-cream-white {
        --text-color: var(--colors-cream);
        --text-hover-color: var(--colors-white);
    }
}

.theme {
    &-filled,
    &-outlined,
    &-makersFilled,
    &-makersOutlined {
        background-position: 0 -98%;
        background-size: 100% 203%;
        transition: background-position $default $ease, color 0.1s $ease 0.075s;

        @media (hover: hover) {
            &:hover,
            &:focus {
                background-position: 0 0%;
            }
        }

        @include button-background(var(--base-color), var(--hover-color));
    }

    &-makersFilled,
    &-makersOutlined {
        font-family: $GTWalsheim;
        font-size: 1.2rem;
        font-weight: 700;
        letter-spacing: 0.3rem;
    }

    &-filled,
    &-makersFilled {
        padding: 0 3rem;
        border-radius: 10rem;
        color: var(--text-color);

        @media (hover: hover) {
            &:hover,
            &:focus {
                color: var(--text-hover-color);
            }
        }
    }

    &-outlined,
    &-makersOutlined {
        padding: 0 2rem;
        border: solid 0.2rem var(--hover-color);
        border-radius: 10rem;
        color: var(--text-color);

        @media (hover: hover) {
            &:hover,
            &:focus {
                color: var(--text-hover-color);
            }
        }
    }

    &-makers-filled,
    &-makers-outlined {
        font-family: $GTWalsheim;
        font-weight: 700;
        letter-spacing: 0.3rem;
    }

    &-inline {
        color: var(--text-color);
        transition: color $fast $ease;

        @media (hover: hover) {
            &:hover,
            &:focus {
                color: var(--text-hover-color);
            }
        }
    }
}

.icon {
    width: 1.6rem;
    height: 1.6rem;

    svg {
        margin-top: 0;
        translate: 0 -0.1rem;
    }
}

@include large {
    .root {
        height: 5rem;
        font-size: 2rem;
        gap: 0.8rem;
    }

    .theme {
        &-filled,
        &-outlined,
        &-inline {
            padding: 0 3rem;
        }

        &-outlined {
            border-width: 0.3rem;
        }

        &-makersFilled,
        &-makersOutlined {
            font-size: 1.4rem;
        }
    }

    .icon {
        width: 2rem;
        height: 2rem;
    }
}
