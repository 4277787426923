@import 'styles/breakpoints';

.root {
    display: flex;
    width: 100%;
    max-width: var(--block-max-width);
    flex-direction: var(--root-flex-direction, column);
    padding: var(--root-padding, var(--block-padding-end) 0);
    margin: 0 auto;
    color: var(--colors-blue);

    &.flushTop {
        padding-top: 0;
    }

    &.flushBottom {
        padding-bottom: 0;
    }
}

.column {
    position: relative;
    width: var(--column-width, 100%);

    &:last-child {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: var(--block-padding-end) 2.8rem;
        background-color: var(--colors-white);
        text-align: center;
    }
}

.image {
    width: 100%;
    max-width: none;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.iconWrapper {
    position: relative;
    display: flex;
    justify-content: center;
    margin-bottom: 2.2rem;

    .icon {
        position: relative;
        width: var(--icon-width, 5.5rem);
        height: var(--icon-height, 5.5rem);
    }
}

.eyebrow {
    margin-bottom: var(--eyebrow-margin-bottom, 1rem);
    text-transform: uppercase;
}

.headline {
    margin-bottom: var(--headline-margin-bottom, 2rem);
    text-transform: uppercase;
}

.copy {
    max-width: 49rem;
    margin-bottom: 0;
}

.cta {
    margin-top: var(--cta-margin-top, 3.6rem);
}

@include smallOnly {
    .root {
        &.flushTopSmall {
            padding-top: 0;
        }
    }
}

@include medium {
    .root {
        --root-flex-direction: row-reverse;
        --column-width: 50%;
        --icon-width: 7.4rem;
        --icon-height: 7.4rem;
        --eyebrow-margin-bottom: 1.5rem;
        --headline-margin-bottom: 3rem;
        --root-padding: var(--block-padding-end) 0;
        --cta-margin-top: 4.8rem;

        &.imageOnLeft {
            --root-flex-direction: row;
        }

        &.flushTop {
            padding-top: 0;
        }

        &.flushBottom {
            padding-bottom: 0;
        }
    }
}
