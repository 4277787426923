@import 'styles/animations';
@import 'styles/breakpoints';

.hero {
    overflow: hidden;
    height: calc(100vh - 6rem - var(--header-height));
    margin-top: 6rem;
}

.image {
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    object-fit: cover;
    object-position: center top;
    transform: translateY(0);
    transition: transform $default $easeInCubic;

    [data-subnav='entered'] & {
        transform: translateY(7.5rem) !important;
    }
}

.body {
    margin-top: 1.2rem;
    text-align: center;
}

.heading {
    position: absolute;
    z-index: 11;
    top: 50%;
    left: 50%;
    color: var(--colors-cream);
    transform: translateX(-50%) translateY(calc(-50% + 6rem));
    transition: transform $default $easeInCubic;

    [data-subnav='entered'] & {
        transform: translateX(-50%) translateY(calc(-50% + 15rem));
    }
}

@include large {
    .body {
        margin-top: 1.8rem;
    }
}
