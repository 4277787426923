@import 'styles/breakpoints';
@import 'styles/fonts';

.root {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.root .icon {
    width: 1em;
    height: 1em;
    margin-bottom: 0.25em;
}

.paragraph {
    font-family: inherit;
    font-size: inherit;
    line-height: 0.85;
    text-align: center;
    text-transform: uppercase;

    em {
        font-size: 1.5em;
        font-style: normal;
    }

    u {
        font-size: 1.5em;
        font-style: normal;
        text-decoration: none;
        -webkit-text-fill-color: transparent;
        -webkit-text-stroke-width: 2px;
    }
}
