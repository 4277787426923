@import 'styles/mixins';
@import 'styles/breakpoints';
@import 'styles/animations';
@import 'styles/fonts';

.hero {
    position: relative;
    display: flex;
    overflow: hidden;
    width: 100%;
    justify-content: center;
    margin-top: 6rem;

    &::before {
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        width: 100%;
        height: 87%;
        background: #ffe4af;
        content: '';
    }
}

.heroInner {
    display: flex;
    width: 100%;
    max-width: var(--block-max-width-plus-padding);
    flex-direction: column;
    padding: 0 var(--block-padding-side);
    gap: 1.4rem;
}

.title {
    font-family: $GTWalsheimUltraBold;
    font-size: 10vw;
    font-style: normal;
    font-weight: 800;
    line-height: 85%; /* 4.08rem */
    text-align: center;
    text-wrap: nowrap;
}

.subTitle {
    margin: 0;
    margin-top: 1em;
    font-family: $GTWalsheim;
    font-size: min(max(1.2rem, 4vw), 2.4rem);
    font-weight: 500;
    line-height: 1.2;
}

.imageWrapper {
    position: relative;
    left: -16%;
    width: 130%;
}

.image {
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    object-fit: cover;
    object-position: center top;
}

.body {
    margin-top: 1.2rem;
    text-align: center;
}

.heading {
    z-index: 11;
    margin-top: 4.8rem;
    color: var(--colors-blue);
    text-align: center;
}

.productGridFlex {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 var(--block-padding-side) var(--block-padding-end);

    &.flushBottom {
        padding-bottom: 0;
    }
}

.productGrid {
    position: relative;
    display: flex;
    width: 100%;
    max-width: var(--block-max-width);
    flex-direction: column;
    padding: 0;
    margin: 0;
    list-style: none;

    &.isRounded {
        @include rounded-corners($overflow: hidden);
    }
}

@include medium {
    .heroInner {
        flex-direction: row;
        gap: 0;
    }

    .heading {
        display: flex;
        width: 50%;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .title {
        font-size: 4.8vw;
    }

    .subTitle {
        font-size: min(max(1.2rem, 2vw), 2.4rem);
    }

    .imageWrapper {
        position: relative;
        left: auto;
        width: 50%;
        aspect-ratio: 1/1;
    }

    .imageWrapper picture {
        position: absolute;
        left: -22%;
        display: block;
        width: 145%;
    }
}

@include large {
    .title {
        font-size: min(5.6vw, 8rem);
    }

    .body {
        margin-top: 1.8rem;
    }

    .productGrid {
        flex-flow: row wrap;

        .gridCard {
            max-width: 50%;
            flex: 1 0 50%;

            &.landscape {
                max-width: 100%;
                flex: 2;
            }

            &.fullWidth {
                max-width: 100%;
                flex: 1 0 100%;
            }
        }
    }
}
