@import 'styles/breakpoints';
@import 'styles/colors';
@import 'styles/mixins';
@import 'styles/animations';

@mixin card-hover {
    &:hover {
        .animateBackground {
            transform: translateY(0%) translateZ(0);
            transition: transform var(--timing-height) var(--easing-spring);
        }
    }
}

.root {
    --timing-height: 1s;
    --easing-spring: cubic-bezier(0.2, 1.4, 0.2, 1);

    position: relative;
    width: 100%;
    height: 100%;
    border: none;
    appearance: none;
    background: var(--colors-ccBlueLight);
    transform: translateZ(0); // fix safari overflow border-radius bug

    @media (hover: hover) {
        @include card-hover;
    }

    &:active {
        @include card-hover;
    }

    article {
        @include flex-center;

        height: 100%;
        flex-direction: column;
        justify-content: space-between;
    }
}

.animateBackground {
    position: absolute;
    right: 0;
    bottom: -6rem;
    left: 0;
    width: 100%;
    color: var(--colors-ccBlueMedium);
    opacity: 0.5;
    transform: translateY(100%);
    transition: transform 1.4s var(--easing-spring);
}

.textContainer {
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.cta {
    padding: 1.15rem 1rem;

    span {
        font-size: 1.4rem;
    }
}

.title {
    position: relative;
    max-width: 21rem;
    padding-bottom: 1rem;
    text-align: center;
}

.image {
    position: relative;
    z-index: 10;
    width: 37vw;
    padding: 0 0.7rem;
    aspect-ratio: 1;
    object-fit: contain;
}

@include medium {
    .textContainer {
        justify-content: end;
    }

    .cta {
        width: fit-content;
        padding: 1.3rem 2.4rem;

        span {
            font-size: 2rem;
        }
    }
}

@include large {
    .title {
        max-width: 25rem;
        padding-bottom: 2.4rem;
    }

    .image {
        width: min(var(--block-max-width) * 0.25, 25vw);
    }
}
