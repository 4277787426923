@import 'styles/breakpoints';

.root {
    display: flex;
    flex-direction: column;
    align-items: center;

    &.masthead {
        max-width: 60rem;
    }
}

.display {
    max-width: var(--displaySize-max-width, 34rem);
    margin: 0 auto;
    text-transform: uppercase;

    &.textAlignLeft {
        margin: 0;
    }
}

.paragraph {
    em {
        font-size: 1.5em;
        font-style: normal;
    }
}

.ultrabold {
    max-width: var(--displaySize-max-width, 28rem);
    margin: 0 auto;
    text-transform: uppercase;

    &.theme--makers-reserve {
        text-transform: none;
    }
}

.masthead,
.displaySmall,
.displayXSmall,
.displaySmallToLarge1 {
    text-transform: uppercase;
}

.displayLarge {
    .paragraph {
        font-size: clamp(3rem, 0.1374rem + 7.6336vw, 6rem);
        line-height: 0.9;
    }
}

.innerHeadline {
    margin: var(--innerHeadline-margin, 0 0 1.2rem 0);
    text-align: center;
    text-transform: uppercase;

    .displaySmallToLarge1 & {
        --innerHeadline-margin: 0 0 2.2rem;
    }

    .ultrabold & {
        --innerHeadline-margin: 0 0 2.4rem;
    }
}

.innerHeadlineDisplay {
    margin: var(--innerHeadlineDisplay-margin, 0 0 1.2rem 0);
}

.innerTextWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    .innerText {
        margin: var(--innerText-margin, 0);
    }

    .innerDisplayText {
        text-transform: uppercase;
    }

    span {
        display: block;
    }
}

.richTextLink {
    z-index: 10;
    margin-left: -1rem;
    cursor: pointer;
}

.ctaWrapper {
    margin-top: 3rem;
}

@include large {
    .root {
        --innerHeadlineDisplay-margin: 0 0 2.4rem;
        --displaySize-max-width: 80rem;

        &.masthead {
            --innerHeadline-margin: 0 0 2rem 0;

            max-width: 69rem;
        }

        &.display,
        &.masthead {
            --innerHeadline-margin: 0 0 2rem 0;
        }

        &.ultrabold {
            --displaySize-max-width: 80rem;
        }

        &.textAlignLeft {
            align-items: flex-start;
        }
    }

    .innerTextWrapper {
        .textAlignLeft & {
            text-align: left;
        }
    }

    .innerHeadline {
        .theme--makers-reserve & {
            margin: 0 0 2.4rem;
        }

        .textAlignLeft & {
            text-align: left;
        }
    }

    .ctaWrapper {
        margin-top: 4rem;
    }
}
