@import 'styles/breakpoints';

.root {
    position: relative;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.inner {
    position: relative;
    display: flex;
    width: 100%;
    height: calc(100vh - #{var(--header-height)});
    min-height: 56rem;
    max-height: 108rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
