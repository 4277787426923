@import 'styles/animations';
@import 'styles/breakpoints';
@import 'styles/colors';
@import 'styles/mixins';
@import 'styles/fonts';

.wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: var(--colors-cream);
}

.root {
    --spinner-color: var(--colors-blue);
    --deck-nav-color: var(--colors-blue);

    position: relative;
    display: flex;
    width: 100%;
    flex-direction: column;
    border-radius: 3rem 3rem 0 0;
    color: var(--colors-blue);

    @include ncdTextureOrangeMedium;

    figure {
        border: 0.2rem solid var(--colors-blue);
    }
}

.inner {
    position: relative;
    display: flex;
    width: 100%;
    max-width: var(--block-max-width);
    flex-direction: column;
    justify-content: center;
    padding: 8rem 2.4rem 0;
    border-radius: 3rem 3rem 0 0;
    margin: 0 auto;
    text-align: center;

    @include ncdTextureOrangeMedium;
}

.sticker {
    position: absolute;
    z-index: 12;
    top: -7rem;
    right: 1rem;
}

.stickerOverlay {
    background-color: rgba(255, 239, 198, 100%);
}

.contentWrapper {
    display: flex;
    max-width: 60rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
}

.eyebrow {
    max-width: 40rem;
    margin: 0 auto;
    font-size: 5.6rem;
    line-height: 0.9;
    text-align: center;
    text-transform: uppercase;

    @include block-text(var(--colors-cream));
}

.headline {
    margin: 0 auto 0.5rem;
    text-transform: uppercase;
}

.subHeadline {
    margin-top: 2.4rem;
    margin-bottom: 2.4rem;
}

.asterisk {
    margin-bottom: 2.4rem;
}

.zipcodeInput {
    position: relative;
}

.inputLockup {
    position: relative;
    width: 100%;
    max-width: 47rem;
    height: var(--input-height, 12rem);

    @include flex-center;

    flex-direction: column;
    gap: 1.2rem;
}

.inputWrap {
    width: 100%;
    height: 100%;
}

.input {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    padding: 1rem 3rem;
    border: 0.2rem solid var(--colors-blue);
    border-radius: 29rem;
    background-color: var(--colors-cream);
    color: var(--colors-blue);
    font-weight: 500;

    &:focus-visible {
        outline: 0.1rem solid var(--colors-blue);
    }

    &::placeholder {
        color: var(--colors-blue);
        font-weight: 500;
        opacity: 0.4;
    }

    .inputSuccess & {
        color: var(--colors-cheeseMexican4Cheese);
        pointer-events: none;

        &::placeholder {
            color: var(--colors-blue);
        }
    }
}

.submitButtonWrap {
    position: absolute;
    right: 1.4rem;
    width: 3.4rem;
    height: 3.4rem;

    @include center-y;
}

.buttonSpinner {
    position: absolute;
    top: 50%;
    width: 2.8rem;
    height: 2.8rem;
    opacity: 0;
    pointer-events: none;
    transform: translateY(-50%);

    .loading & {
        opacity: 1;
    }
}

.submitArrow {
    width: 1.3rem;
    height: 1.3rem;
    color: var(--colors-blue);
}

.submitButton {
    width: 100%;
    height: 100%;
    border: 2px solid var(--colors-blue);
    border-radius: 100%;

    @include ncdTextureOrangeMedium;

    box-shadow: -1px 1px 0 var(--colors-blue), -2px 2px 0 var(--colors-blue),
        -3px 3px 0 var(--colors-blue);
    transition: transform 0.25s;
    will-change: transform;

    @media (hover: hover) {
        &:hover {
            transform: scale(1.1);

            &.disabled {
                transform: none;
            }
        }
    }

    .loading & {
        opacity: 0;
    }

    .inputSuccess & {
        display: none;
    }
}

.reCaptchaLegal {
    margin: 1.4rem;

    div {
        color: var(--colors-blue);
        opacity: 0.6;
    }
}

.joinError {
    color: var(--colors-cheeseSharpCheddar);
}

.deckWrapper {
    width: 100%;
}

.checkboxWrapper {
    margin: 2.4rem 0 2.4rem 2.8rem;

    &.hide {
        display: none;
    }
}

.checkbox {
    position: relative;
    display: block;
    width: 100%;
    padding-left: 2rem;
    cursor: pointer;
    text-align: left;
    user-select: none;

    a {
        text-decoration: underline;
    }

    input {
        position: absolute;
        width: 0;
        height: 0;
        cursor: pointer;
        opacity: 0;
    }

    input:checked ~ .checkmark {
        background-color: var(--colors-cream);

        &::after {
            display: block;
        }
    }
}

.checkboxError {
    color: var(--colors-cheeseSharpCheddar);
}

.checkmark {
    position: absolute;
    top: 50%;
    left: -2rem;
    width: 2.2rem;
    height: 2.2rem;
    border: none;
    border: 2px solid var(--colors-cream);
    border-radius: 5px;
    background-color: var(--colors-cream);
    transform: translateY(-50%);

    &::after {
        position: absolute;
        display: none;
        content: '';
    }
}

.checkbox .checkmark::after {
    top: 2px;
    left: 6px;
    width: 7px;
    height: 12px;
    border: solid var(--colors-blue);
    border-width: 0 2px 2px 0;
    border-radius: 2px;
    transform: rotate(45deg);
}

@include medium {
    .eyebrow {
        max-width: none;
    }

    .sticker {
        top: -10rem;
        right: 4rem;
    }

    .inner {
        padding: 10.5rem var(--block-padding-side)
            var(--last-block-padding-bottom);
    }
}

@include large {
    .root {
        border-radius: 6rem 6rem 0 0;

        figure {
            border-width: 0.4rem;
        }
    }

    .sticker {
        top: -13rem;
        right: 4rem;
    }

    .contentWrapper {
        align-items: flex-start;
    }

    .submitButtonWrap {
        width: 4rem;
        height: 4rem;
    }

    .submitArrow {
        width: 1.8rem;
        height: 1.8rem;
    }

    .inner {
        flex-direction: row;
        justify-content: space-between;
        border-radius: 6rem 6rem 0 0;
        text-align: left;
    }

    .input {
        border-width: 0.4rem;
    }

    .inputLockup {
        --input-height: 15.4rem;

        gap: 1.8rem;
    }

    .eyebrow {
        font-size: 9.6rem;
        text-align: left;
    }

    .subHeadline {
        margin-top: 3.6rem;
        margin-bottom: 3.6rem;
    }

    .asterisk {
        margin-bottom: 3.6rem;
    }

    .deckWrapper {
        width: 50%;
    }
}

@include xLarge {
    .root {
        max-height: 100rem;
    }
}
