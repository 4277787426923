@import 'styles/animations';
@import 'styles/breakpoints';
@import 'styles/mixins';

@mixin video-player-hover {
    .background {
        &::after {
            background-color: rgba(0, 0, 0, 40%);
        }
    }
}

.root {
    overflow: hidden;
    width: 100%;
}

.videoPlayerYT {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: var(--videoPlayerYT-padding-bottom, 158%);
    cursor: pointer;

    @media (hover: hover) {
        &:hover {
            @include video-player-hover;
        }
    }

    &:active {
        @include video-player-hover;
    }

    &.isActive {
        .foreground {
            opacity: 1;
        }
    }

    &.heroVideo {
        height: 100%;
        padding-bottom: 0;
    }
}

.image,
.background,
.foreground,
.videoOverlayBackdrop,
.videoOverlay,
.iFrame {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.image {
    object-fit: cover;
    object-position: center;

    &:not(.heroVideo &) {
        @include rounded-corners;
    }
}

.background {
    &::after {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 20%);
        content: '';
        transition: background-color $easeOutQuint $slow;
    }

    &:not(.heroVideo &)::after {
        @include rounded-corners;
    }
}

.foreground {
    display: flex;
    max-width: 59.6rem;
    flex-direction: column;
    align-items: var(--foreground-align-items, center);
    justify-content: var(--foreground-justify-content, center);
    padding: var(--foreground-padding, var(--block-padding));
    color: var(--colors-cream);
    opacity: 0;
    text-align: var(--foreground-text-align, center);
    transition: opacity $slow;

    .centerPlayButton & {
        max-width: 100%;
    }
}

.eyebrow {
    margin-bottom: 1rem;
    text-transform: uppercase;
}

.headline {
    max-width: var(--headline-max-width, 36rem);
    text-transform: uppercase;
}

.playVideo {
    margin-top: var(--playVideo-margin-top, 1.5rem);

    .centerPlayButton & {
        margin-top: 0;
    }
}

.videoOverlay {
    display: flex;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    padding: var(--videoOverlay-padding, 0);
    opacity: 0;
    pointer-events: none;
    transition: opacity $easeOutQuint $slow,
        visibility $easeOutQuint $slow $slow;
    visibility: hidden;

    &.isOpen {
        opacity: 1;
        pointer-events: auto;
        transition: opacity $easeOutQuint $slow, visibility $easeOutQuint $slow;
        visibility: visible;
    }
}

.videoOverlayBackdrop {
    background-color: rgba(0, 0, 0, 80%);

    &.transparent {
        background-color: transparent;
    }
}

.videoOverlayClose {
    position: absolute;
    top: 0;
    right: 0;
    padding: var(--videoOverlayClose-padding, 2rem 2.5rem);
}

.videoOverlayCloseIcon {
    position: relative;
    width: 2.2rem;
    height: 2.2rem;
    color: var(--colors-cream);

    svg {
        width: 2.2rem;
        height: 2.2rem;
    }
}

.videoOverlayInnerWrap {
    position: relative;
    width: 100%;
    max-width: var(--block-max-width-plus-padding);
    padding: var(--block-padding);
}

.videoOverlayInner {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    background-color: var(--colors-cheeseBlackPepper);
    opacity: 0;
    transition: opacity $easeOutQuint $slow,
        visibility $easeOutQuint $slow $slow;
    visibility: hidden;

    .isOpen & {
        opacity: 1;
        transition: opacity $easeOutQuint $superSlow * 2 0.2s,
            visibility $easeOutQuint $superSlow 0.2s;
        visibility: visible;
    }
}

@include medium {
    .videoPlayerYT {
        --videoPlayerYT-padding-bottom: 56.25%;
        --videoOverlay-padding: var(--block-padding-end);
        --foreground-justify-content: flex-end;
        --foreground-align-items: flex-start;
        --foreground-text-align: left;

        &.heroVideo {
            --foreground-padding: var(--block-padding-end)
                var(--block-padding-side) 16rem;

            .foreground {
                left: 50%;
                max-width: var(--block-max-width-plus-padding);
                transform: translateX(-50%);
            }
        }

        &.isCentered {
            --foreground-justify-content: center;
            --foreground-align-items: center;
            --foreground-text-align: center;
        }

        &.centerPlayButton {
            --foreground-padding: 0;
            --playVideo-margin-top: 0;
        }
    }

    .videoOverlay {
        --videoOverlayClose-padding: 3.1rem 3rem;
    }

    .foreground {
        .embeddedEntry & {
            padding: 4.8rem;
        }
    }
}

@include large {
    .videoPlayerYT {
        --headline-max-width: 60rem;
        --playVideo-margin-top: 3rem;

        &.heroVideo {
            --foreground-padding: var(--block-padding-end)
                var(--block-padding-side) 22rem;
        }

        &.centerPlayButton {
            --foreground-padding: 0;
            --playVideo-margin-top: 0;
        }
    }
}

@include xLarge {
    .foreground {
        max-width: 66.6rem;
    }
}
