@import 'styles/breakpoints';
@import 'styles/animations';
@import 'styles/colors';
@import 'styles/mixins';

@keyframes reveal {
    0% {
        transform: rotate(12deg);
    }

    50% {
        transform: rotate(36deg);
    }

    100% {
        transform: rotate(12deg);
    }
}

.sticker {
    width: 13.8rem;
    height: 13.8rem;
    border: 0.3rem solid var(--colors-blue);
    border-radius: 50%;
    box-shadow: -4px 4px 0 0 var(--colors-blue);
    cursor: pointer;

    &:hover {
        .image-left {
            transform: rotate(0deg);
        }

        .image-right {
            transform: rotate(24deg);
        }
    }
}

.image {
    .isInView & {
        animation: reveal 0.75s $easeInOut;
    }

    transition: transform $default $easeInOutCirc;

    &-left {
        transform: rotate(-12deg);
    }

    &-right {
        transform: rotate(12deg);
    }
}

.fact {
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    color: var(--colors-blue);
}

.factHeadline {
    padding-bottom: 0.6rem;
    font-size: 1.4rem;
    text-align: center;
    text-transform: uppercase;
}

.factCopy {
    font-size: 1.2rem;
    line-height: 1.2;
    text-align: center;
}

.overlay {
    opacity: 0;
    transition: opacity $fast $easeOut;

    &.isOpen {
        opacity: 1;
        transition: opacity $fast $easeOut;
    }
}

.overlay,
.overlayBackdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 40%);
}

.overlayClose {
    position: absolute;
    top: 1.2rem;
    right: 1.2rem;
    border-radius: 50%;
    background-color: var(--colors-blue);
    color: var(--colors-cream);
}

.overlayInner {
    @include center-xy;

    position: absolute;
    display: flex;
    width: 90%;
    max-width: 35.5rem;
    flex-direction: column;
    align-items: center;
    padding: 4.8rem 1.2rem;
    border: 0.2rem solid var(--colors-blue);
    border-radius: 2rem;
    background-color: var(--colors-white);
    color: var(--colors-blue);
}

.textWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.overlayEyebrow,
.overlayHeadline {
    text-transform: uppercase;
}

.overlayHeadline {
    margin-top: 1.2rem;
}

.overlayCopy {
    margin: 2.4rem 0;
}

.cta span {
    border-radius: 3rem !important;
}

.flag {
    position: absolute;
    top: -0.1rem;
    left: 2rem;
    width: 15%;
}

@include smallOnly {
    .imageWrapper {
        img {
            width: clamp(12rem, -28rem + 66.6667vh, 20rem);
        }
    }
}

@include medium {
    .overlayInner {
        max-width: 108.2rem;
        flex-direction: row;
        padding: 9.3rem 6.9rem;
        gap: 4rem;
    }

    .flag {
        left: 4rem;
        width: 8%;
    }

    .overlayClose {
        top: 2rem;
        right: 2rem;
    }

    .overlayHeadline {
        margin-top: 2.4rem;
    }

    .overlayCopy {
        margin: 2.4rem 0 4.8rem;
    }
}

@include large {
    .sticker {
        width: 20rem;
        height: 20rem;
    }

    .fact {
        padding: 2.4rem;
    }

    .factHeadline {
        font-size: 1.8rem;
    }

    .factCopy {
        font-size: 1.4rem;
        line-height: 1.5;
    }
}
