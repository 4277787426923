@import 'styles/breakpoints';

.root {
    position: relative;
    width: 100%;
}

.inner {
    display: flex;
    width: 100%;
    height: 100%;
    padding: 10px;
}

.content {
    position: relative;
    display: flex;
    overflow: hidden;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2.4rem 2.4rem 4.8rem;
    border-radius: 2rem;
    background-color: var(--colors-blue);
    color: var(--colors-cream);
}

.lockup {
    display: flex;
    flex-direction: column;
    align-items: center;

    svg {
        scale: 1.45;
        translate: 0 -19%;
    }
}

.imageContainer {
    width: 100%;
    margin-bottom: 4rem;
    aspect-ratio: 307/256;
}

.image {
    width: 100%;
    height: 100%;
    border-radius: 2rem;
    object-fit: cover;
}

.copy {
    display: flex;
    padding: 2rem 0;
    text-align: center;
}

@include medium {
    .inner {
        align-items: center;
        justify-content: center;
        padding: 2rem;
    }

    .content {
        max-width: var(--block-max-width-plus-padding);
        flex-direction: row-reverse;
        padding: var(--block-padding-side);
        gap: var(--block-padding-side);
    }

    .lockup {
        width: 50%;
    }

    .copy {
        max-width: 53.6rem;
    }

    .imageContainer {
        width: 50%;
        margin-bottom: 0;
        aspect-ratio: 576/628;
    }
}

@include xLarge {
    .copy {
        padding: 4.8rem;
    }
}
